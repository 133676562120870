import Vue from 'vue'
import App from './home'
import vuetify from "../../plugins/vuetify"
import axios from "axios"
import Qs from "qs"
import store from './store'
import router from '../../router'

axios.interceptors.request.use(function(config) {
  config.data = Qs.stringify(config.data);
  return config;
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
  store,
}).$mount('#app')
