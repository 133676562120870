import Vue from "vue";
import Router from "vue-router";
// import MainView from "../components/home.vue";
// import LoginView from "../components/login.vue"

Vue.use(Router);

export default new Router({
    mode:'hash',
    routes:[
        {
            path:"/",
            name:"main",
            component:()=>import("../components/home.vue")
        },
        {
            path:"/login",
            name:"login",
            component:()=>import("../components/login.vue"),
        }
    ]
});