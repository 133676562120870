<template>
  <div>
    <router-view ></router-view>
  </div>
</template>
<script>
import ck from '../../js/cookieset';

export default {
  name: 'home',
  data: () => ({
  }),
  methods: {
  },
  beforeCreate(){
    const islogin=this.$store.state.islogin;
    const logstatus=ck.getCookie("login-status");
    if(!logstatus&&!islogin){
      this.$router.push("/login")
    }
  },
};
</script>
